@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
    
}

.header {
    display: flex;
    align-items: center;
  
    margin-bottom: 5px;
  
    .titleModal {
        font-family: $font-third;
        font-size: 20rem;
        font-weight: bold;
        letter-spacing: 0.18px;
        text-align: center;
        color: #333;
        width: 94%;
    }
  
    .closeIcon {
        width: 6%;
        text-align: right;
        cursor: pointer;
    
        img {
            width: 15px;
        }
    }
}

.detail {
    font-family: $font-third;
    font-size: 11.56rem;
    font-weight: 600;
    line-height: 1.67;
    letter-spacing: 0.6px;
    text-align: center;
    color: #7c8085;
    margin-bottom: 20px;
}

.list {
    height: 550px;

    overflow: visible;
    overflow-y: scroll;

    margin-bottom: 15px;

    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        height: 200px;
    }

    &::-webkit-scrollbar {
        width: 10px;
        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            width: 4px;
        }
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        height: 100px;
        background: #aab5c9;
        border-radius: 10px;

        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            height: 50px;
        }
    }
}

.loadMore {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.loadMoreBtn {
    height: 30rem;
    width: 80rem;
    border-radius: 5rem;
    margin: 5rem 8rem;
    font-size: 10rem;
    font-weight: 500;
}