@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";


.sectionFour {
  margin-top: 20rem;
  background: url("assets/background.png") center/cover no-repeat;
  min-height: 553rem;

  .sectionFourContainer {
    min-height: 553rem;

    @media screen and (min-width: 320px) and (max-width: 476px) {
      flex-direction: column-reverse;
    }

    .left {
      overflow: hidden;

      .image {
        width: 619rem;
        height: 318rem;
        margin-left: -72rem;
        margin-top: 25rem;

        // on mobile portrait
        @media screen and (min-width: 320px) and (max-width: 476px)  {
          margin-top: 55rem;
          margin-bottom: 30rem;
          width: auto;
          height: 345rem;
          margin-left: -98rem;
        }

        // on mobile landscape
        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
          margin-top: 55rem;
          margin-bottom: 30rem;
          width: auto;
          height: 345rem;
          margin-left: -98rem;
        }
      }

      @include breakpoint("md-max") {
        max-width: 100% !important;
      }
    }

    .right {
      max-width: 380rem;

      @media screen and (min-width: 320px) and (max-width: 476px)  {
        margin-top: 74rem;
        text-align: center;
        max-width: 100%;
        padding: 0 35rem;
      }

      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        text-align: left;
        padding: 0 35rem;
      }

      .title {
        font-family: $font-third;
        font-size: 35rem;
        line-height: 1;
        font-weight: bold;
        color: #ffffff;

        @media screen and (min-width: 320px) and (max-width: 476px)  {
          font-size: 32.5rem;
          padding: 0 72rem;
        }

        @media only screen and (min-width: 563px) and (max-width: 768px) {
          font-size: 32.5rem;
          padding: 0 72rem;
        }

        @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
          padding: 0rem !important;
        }
        @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
          padding: 0rem !important;
        }
      }

      .info {
        font-family: $font-primary;
        font-size: 15rem;
        line-height: 1.33;
        text-align: left;
        color: #ffffff;
        margin-top: 20rem;

        @media screen and (min-width: 320px) and (max-width: 476px)  {
          text-align: center;
          font-size: 18rem;
          line-height: 1.67;
        }
      }

      .iconGroup {
        display: flex;
        margin-top: 24rem;

        @media screen and (min-width: 320px) and (max-width: 476px)  {
          justify-content: center;
        }

        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
          justify-content: flex-start;
        }

        .icon {
          width: auto;
          height: 30rem;
          margin-right: 15rem;

          @media screen and (min-width: 320px) and (max-width: 476px)  {
            height: 36rem;
            margin-right: 23.5rem;
          }
        }
      }
    }
  }
}
