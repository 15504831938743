@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  padding: 18.5rem 0 16rem;
  box-sizing: border-box;
  z-index: 999;
  transition: $transition-all;

  // on mobile portrait
  @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
    padding: 20rem 0;
    z-index: 9999;
  }

  // on mobile landscape
  @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
    padding: 33rem 40rem;
    background: transparent;
    z-index: 9999;
  }

  // on iPad normal portrait
  @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
    padding: 33rem 40rem;
  }

  // on iPad pro portrait
  @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
    // padding: 33rem 40rem;
  }

  // on iPad all landscape
  @media screen and (orientation:landscape) and (min-width: 1366px) {
  }

  &.containerScrolled {
    // background: #0059c3;
  }
}

.fullWidth {
  max-width: none;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33rem 100rem;

  // on mobile portrait
  @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
    padding-top: 23rem;
    padding-bottom: 45rem;
    padding-left: 35.5rem;
    padding-right: 36.5rem;
  }

  // on mobile landscape
  @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
    padding-left: 35.5rem;
    padding-right: 36.5rem;
    padding-top: 0rem;
  }

  // on iPad normal portrait
  @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 0rem;
    padding-left: 20rem;
    padding-right: 33rem;
  }

  // on iPad pro portrait
  @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
    padding-top: 20rem;
    padding-left: 70rem;
    padding-right: 70rem;
  }

  // on iPad all landscape
  @media screen and (orientation:landscape) and (min-width: 1366px) {
  }
}

.left {
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 320px) and (max-width: 476px) {
    flex-shrink: 10;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 40rem;

  .joinBtn {
    background: url("/assets/img/home/btn-orange-bg.svg") center/cover no-repeat;
    border-radius: 5rem;
    width: 126.5rem;
    height: 38rem;
    display: flex;
    align-items: center;
    margin-right: 10rem;
    text-align: center;

    @media screen and (min-width: 320px) and (max-width: 476px) {
      width: 178.5rem;
      height: 53rem;
    }

    .telegramIcon {
      width: 14.5rem;
      height: 12.5rem;
      margin-right: 7.5rem;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        width: 21rem;
        height: 18rem;
        margin-right: 10rem;
      }
    }

    p {
      color: $color-light;
      font-size: 12.5rem;
      font-weight: 500;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        font-size: 18rem;
        line-height: 1.6;
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 476px) {
    margin-left: 10rem;
  }
}

.logo {
  width: 109rem;

  @include breakpoint("md-max") {
    max-width: 87rem;
    min-width: 40rem;
    width: 100%;
    flex-shrink: 10;
  }
}

.logoLink {
  padding: 0;

  .logo {
    width: 115rem;
    height: 61rem;

    @media screen and (min-width: 320px) and (max-width: 476px) {
      width: 128rem;
      max-width: 128rem;
    }
  }
}

.nav {
}

.menuWrapper {
  width: 100%;
  margin-left: 27rem;

  @include breakpoint("md-max") {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    top: 100%;
    left: 0;
    background: $bg-light;
    margin: 0;

    transition: $transition-all;
    transform-origin: top;
    transform: scaleY(0) translateY(-100rem);
    opacity: 0;

    .open & {
      transform: scaleY(1) translateY(0);
      opacity: 1;
    }


  }

  @media screen and (min-width: 320px) and (max-width: 476px) {
    height: calc(100vh - 42rem);
    overflow-y: auto;
  }
}

.searchWrapper {
  width: 100%;
  max-width: 422rem;
  flex-shrink: 10;
}

.search {
  width: 100%;

  @include breakpoint("md-max") {
    max-width: none;
  }
}

.menuBody {
  width: 100%;
  display: flex;
  align-items: center;

  @include breakpoint("md-max") {
    flex-direction: column;
    align-items: flex-start;
    padding: 24rem 23rem 28rem;
  }
}

.menuActions {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 422rem;
}

.menu {
  width: 100%;
  list-style: none;
  min-width: 0;
  margin: 0 0 0 45rem;
  padding: 0;

  display: flex;
  align-items: center;

  @include breakpoint("md-max") {
    margin: 30rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.menuItem {
  margin: 0;
  padding: 0;

  &:not(:first-child) {
    margin-left: 30rem;

    @include breakpoint("md-max") {
      margin: 15rem 0 0;
    }
  }
}

.menuLink {
  font-family: $font-primary;
  font-size: 13rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #2e3345;
  padding: 5rem 0;

  transition: $transition-all;

  &:link {
    text-decoration: none;
  }

  &:visited {
  }

  &:hover {
    opacity: 0.5;
    color: #2e3345;
  }

  &:active {
  }
}

.menuLinkActive {
}

.button {
  flex-shrink: 0;
}

.buttonNotify {
  margin-left: 10rem;
}

.buttonNotifyIcon {
  width: 31rem;
  height: auto;
}

.hamburger {
  margin-left: 23rem;
  flex-shrink: 0;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchDropdownMobileWrapper {
  width: 100%;
}

.searchDropdownMobile {
  width: 100%;
  background: transparent;
  padding-top: 20rem;
}
