@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
}

.isNone {
    display: flex;
}

.item {
    display: flex;
    align-items: center;
    margin: 10px;
    border: solid 1px #f0f3f6;
    border-radius: 10px;
    padding: 6px 6.5px 6px 10px;

    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        padding: 5px;
    }

    .images {
        width: 13%;
        text-align: center;

        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            width: 20%;
        }

        img {
            width: 38px;
            margin-right: 15px;
    
            @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                width: 20px;
                margin-right: 0px;
            }
        }
    }


    .right {
        width: 87%;

        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            width: 79%;
            padding-left: 1%;
        }

        .top {
            display: flex;
            align-items: center;

            @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                justify-content: space-between;
            }

            .titleNoLive {
                font-family: $font-third;
                font-weight: 700;
                font-size: 12rem;
                margin-bottom: 0;

                @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                    font-size: 8px;
                }
            }

            .title {
                font-family: $font-third;
                font-weight: 700;
                font-size: 12rem;
                margin-bottom: 0;
    
                @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                    width: 50%;
                    font-size: 8px;
                }
            }

            .isLive {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                background-color: #37a742;
                padding: 0 7px;
                margin-left: 10px;
        
                @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                    padding: 3px 2px;
                    margin-left: 0px;
                    width: 50%;
                }
        
                .circle {
                    width: 5px;
                    height: 5px;
                    background-color: #fff;
                    border-radius: 2.5px;
                    margin-right: 2px;
        
                    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                        width: 3px;
                        height: 3px;
                        border-radius: 1.5px;
                    }
                }
        
                p {
                    font-family: $font-third;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 2;
                    letter-spacing: 0.6px;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 0;
        
                    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
                        font-size: 7px;
                        line-height: 1;
                    }
                }
            }
        }

        .bottom {
            font-family: $font-third;
            margin-bottom: 0;
            font-size: 11rem;
            font-weight: 600;
            line-height: 1.36;
            letter-spacing: 0.55px;
            text-align: left;
            color: #7c8085;
        }
    }
}