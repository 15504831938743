.pointSection {
  width: 376rem;

  .point {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding: 1.5rem;

    .boxes {
      position: relative;
      right: -12px;
      border-radius: 4rem;
      box-shadow: 0 0 0 3rem #336dc7;
    }

    .pointProgressBar{
      // height: 15rem;
      // position: relative;
      // right: -12px;

      border-radius: inherit;
      width: 150rem;
      font-size: 10rem;
      background-color: #28539c;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.58);

      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 1024px) {
        // right: -6px;
      }
    }
    .avatarWrapper {
      background-color: #336dc7;
      width: 42rem;
      height: 42rem;
      border-radius: 50%;
    }
    .avatar {
      position: absolute;
      top: 7.5rem;
      right: 7.5rem;
      width: 30rem;
      border-radius: 50%;
      box-shadow: 0 0 0 3rem #ffffff;
      z-index: 110;
    }
  }
}