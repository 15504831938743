@import "variables/common";
@import "variables/breakpoints";
@import "mixins/breakpoints";

@import "common";
@import "content";
@import "transitions";
@import "animations";

@media screen and (orientation:landscape) and (max-height: 562px) {
  .walletconnect-modal__base {
    max-height: 100%;
  }
  .walletconnect-connect__buttons__wrapper__android {
    margin: auto !important;
    height: 20vh !important;
  }
}

#root {
  height: 100%;
  min-height: -webkit-fill-available;
}

body {
  @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
  }
}