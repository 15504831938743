@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";


.sectionTwo {
  padding: 121rem 100rem;
  background: url("assets/background.png") center/cover no-repeat;
  

  @include breakpoint("sm-max") {
    padding: 90rem 35rem;
  }

  @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px)  {
    padding: 120rem 63rem;
  }

  @media only screen and (min-width: 563px) and (max-width: 768px) {
    padding: 120rem 63rem !important;
  }
  

  .item {
    .left, .right {
      position: relative;
    }
    .right {
      padding-left: 30rem;
    }

    .titleWrapper {
      display: flex;
      align-items: center;

      @include breakpoint("sm-max") {
        justify-content: center;
      }

      @media only screen and (min-width: 563px) and (max-width: 768px) {
        justify-content: center !important;
        max-width: 100% !important;
      }

      .title {
        max-width: 245rem;
        
        @include breakpoint("sm-max") {
          max-width: 100%;
        }
      }

      .tokenIcon {
        width: 64rem;
        height: 64rem;
        margin-left: -15.5rem;
        @include breakpoint("sm-max") {
          margin-left: 15.5rem !important;
        }
      }

      .atackIcon {
        width: 82rem;
        height: 74rem;
        margin-left: 8rem;
      }

      .artboardIcon {
        width: 83rem;
        height: 52rem;
        margin-left: 0.5rem;
      }
    }
  }

  .itemOne {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    // on mobile portrait
    @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    // on mobile landscape
    @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
    }

    // on iPad normal portrait
    @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
      grid-template-columns: 1fr;
    }

    // special for ipad pro 11
    @media screen and (orientation:portrait) and (min-width: 834px) and (max-width: 1194px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
    }

    // on iPad all landscape
    @media screen and (orientation:landscape) and (min-width: 1366px) {
    }

    .right {
      @include breakpoint("sm-max") {
        grid-row: 1/1;
      }

      // on mobile landscape
      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        padding-left: 10px;
      }

      // on Ipad not pro
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        // padding-left: 40rem;
      }
    }
  }

  .itemTwo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-top: 136.1rem;
    padding-bottom: 136.5rem;

    // on mobile portrait
    @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    // on mobile landscape
    @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {

    }

    // on iPad normal portrait
    @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
      grid-template-columns: 1fr;
    }

    @media screen and (orientation:portrait) and (min-width: 834px) and (max-width: 1194px) {
      grid-template-columns: 1fr 1fr;
    }

    // min width 1024px
    @media screen and (orientation:portrait) and (orientation:landscape) and (min-width: 1024px) {
      // grid-template-columns: 1fr 1fr;
    }

    @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
      // grid-template-columns: 1fr 1fr;
    }

    // on iPad all landscape
    @media screen and (orientation:landscape) and (min-width: 1366px) {
    }

    .left {
      // on mobile landscape
      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        padding-right: 10px;
      }

      // on Ipad not pro
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding-right: 30rem;
      }
    }

    @include breakpoint("sm-max") {
      padding-top: 55rem;
      padding-bottom: 55rem;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      @media only screen and (min-width: 563px) and (max-width: 768px) {
        margin:auto !important;
      }
    }
  }

  .title {
    font-family: $font-third;
    font-size: 35rem;
    line-height: 1.00;
    letter-spacing: -0.88rem;
    text-align: left;
    color: #FFFFFF;

    @include breakpoint("sm-max") {
      font-size: 33rem;
    }
  }

  .info {
    font-family: Inter;
    font-size: 15.5rem;
    line-height: 1.65;
    text-align: left;
    color: #FFFFFF;
    margin-top: 15rem;
    max-width: 388rem;

    @include breakpoint("sm-max") {
      text-align: center;
      max-width: none;
      font-size: 18rem;
    }
    @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
      font-size: 20rem !important;
    }
    @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
      font-size: 20rem !important;
    }
  }

  .videoContainer {
    width: 632rem;
    height: 382rem;
    background: url("assets/video-frame.png") center/cover no-repeat;
    border-radius: 10rem;
    position: relative;

    @include breakpoint("sm-max") {
      width: 100%;
      height: auto;
      margin-top: 40rem;
      background-size: contain;
      border-radius: 20rem !important;
    }

    @media only screen and (min-width: 563px) and (max-width: 768px) {
      width: 632rem !important;
      height: 382rem !important;
    }
  }

  .video {
    margin-top: 26rem;
    width: 100%;
    border-radius: 0 0 10rem 10rem;

    @include breakpoint("sm-max") {
      margin-top: 26rem;
    }
  }
}


@include breakpoint("sm-max") {
  .right {
    padding-left: 0rem !important;
  }
}

.left {
  @media only screen and (min-width: 563px) and (max-width: 768px) {
    margin: auto !important;
  }
}




