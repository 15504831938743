@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";


.sectionOne {
  .headerContainer {
    background: url("/assets/img/bg-watermark.png") center/cover no-repeat;
    min-height: 650rem;
    position: relative;

    display: grid;
    padding: 123rem 0rem;
    grid-template-columns: 1fr 1fr;

    // on mobile portrait
    @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
      background: url("assets/bg-section1-mobile.png") center/cover no-repeat;
      grid-template-columns: 1fr;
      min-height: 860rem;
      padding: 125rem 33rem 0;
    }

    // on mobile landscape
    @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
      min-height: 600rem;
      align-items: flex-start;
      padding: 123rem 33rem 0;
      grid-template-columns: 1fr 1fr;
    }

    // on iPad normal portrait
    @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
      min-height: 600rem;
      grid-template-columns: 1fr 1fr;
    }

    // on iPad pro portrait
    @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
      min-height: 600rem;
      padding-top: 30rem;
      padding-left: 70rem;
    }

    // on iPad all landscape
    @media screen and (orientation:landscape) and (min-width: 1366px) {
    }

    .leftHeader {
      padding-left: 100rem;
      padding-top: 30rem;
      z-index: 999;

      // on mobile portrait
      @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
        padding-left: 0;
        padding-top: 0;
      }

      // on mobile landscape
      @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
        padding-top: 30rem;
        padding-left: 40rem;
      }

      // on iPad normal portrait
      @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
        padding-top: 30rem;
        padding-left: 60rem;
      }

      // on iPad pro portrait
      @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
        padding-top: 120rem;
        padding-left: 0rem;
      }

      // on iPad all landscape
      @media screen and (orientation:landscape) and (min-width: 1366px) {
      }

      .title .heading {
        font-size: 45rem;
        line-height: 1.22;
        letter-spacing: -1.13rem;
        text-align: left;
        color: $color-light;
        font-weight: bold;
        margin-bottom: 45rem;

        @include breakpoint("sm-max") {
          margin-bottom: 30rem;
        }
      }

      .detail {
        font-size: 18rem;
        line-height: 1.67;
        color: $color-light;
        margin: 0;

        // on mobile landscape
        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
          text-align: left;
        }

        @media only screen and (min-width: 563px) and (max-width: 768px) {
          text-align: left;
        }
      }

      .textLink {
        color: $color-light;
        &:hover {
          color: #0089fe;
        }
      }

      .buttonContainer {
        display: flex;
        margin: 29rem 0 35rem;

        // on mobile portrait
        @include breakpoint("sm-max") {
          display: flex;
          justify-content: center;
          margin: 30rem 0 20rem;
        }

        // on mobile landscape
        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        }

        // on iPad portrait
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          justify-content: flex-start;
          margin: 30rem 0 20rem;
        }

        .comingBtn {
          display: flex;
          align-items: center;
          height: 44rem;
          border-radius: 6rem;
          border: 1rem solid #eceef3;
          margin-right: 13.5rem;
          padding: 12rem 15rem;
          width: auto;
          font-size: 15rem;
          font-weight: 500;


          .pancakeIcon {
            width: 30rem;
            margin-right: 5.5rem;
          }
        }

        .comingBtn.ido {
          color: #2c2a38;
        }

        .comingBtn.game {
          color: #0089fe;
        }
      }

      .binanceSmart {
        display: flex;
        align-items: center;

        // on mobile portrait
        @media screen and (min-width: 320px) and (max-width: 476px) {
          justify-content: center;
        }

        // on mobile landscape
        @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
          text-align: left;
        }

        @media only screen and (min-width: 563px) and (max-width: 768px) {
          text-align: left;
        }

        .text1 {
          font-size: 15rem;
          line-height: 1.6;
          color: #ffffff;
        }

        .bnbIcon {
          width: 18rem;
          margin: 0 8rem;
        }

        .text2 {
          font-size: 15rem;
          font-weight: 600;
          line-height: 1.6;
          color: #ffffff;
        }
      }
    }

    .rightHeader {
      position: absolute;
      right: 0;
      bottom: 0;

      .baseImage {
        width: 153.07%;
        max-width: 903.5rem;

        // on mobile portrait
        @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
          display: none;
        }

        // on mobile landscape
        @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
          width: 133.07%;
          max-width: 803.5rem;
        }

        // on iPad normal portrait
        @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
          width: 133.07%;
          max-width: 803.5rem;
        }

        // on iPad pro portrait
        @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
          width: 133.07%;
          max-width: 803.5rem;
        }

        // on iPad all landscape
        @media screen and (orientation:landscape) and (min-width: 1366px) {
        }
      }

      .iStack1, .iStack2, .iStack3, .iStack4 {
        position: absolute;

        @media screen and (min-width: 320px) and (max-width: 476px)  {
          display: none;
        }
      }

      .iStack1 {
        width: 112.5rem;
        height: 113rem;
        right: 64.5rem;
        top: -42rem;
      }

      .iStack2 {
        width: 79.5rem;
        height: 76.5rem;
        left: 290rem;
        top: -44.5rem;
      }

      .iStack3 {
        width: 50rem;
        height: 59rem;
        left: 220rem;
        bottom: 115rem;
      }

      .iStack4 {
        width: 54rem;
        height: 57.5rem;
        right: 45rem;
        bottom: 254.5rem;
      }
    }
  }
}
