@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
  .header {
    display: grid;
    grid-template-columns: 95% 5%;

    .heading {
      font-family: $font-third;
      font-size: 18rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: 0.9rem;
      text-align: center;
      color: $color-gray-dark;

      margin: 0;

      @include breakpoint("sm-max") {
        font-size: 21rem;
      }
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .data {
    border: 1rem solid #ebebeb;
    padding: 20rem 20rem 19rem;
    border-radius: 7.5rem;
    margin: 20rem 0 0;

    .label {
      font-family: $font-third;
      font-size: 12rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #9a9ca6;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .address {
      display: flex;
      align-items: center;
      font-family: $font-third;
      font-size: 17.5rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #282828;
      margin-top: 18rem;

      .icon {
        flex-shrink: 0;
        margin-right: 9rem;
      }
    }

    .dataActions {
      display: flex;
      align-items: center;
      margin-top: 18rem;

      .dataAction {
        font-family: $font-third;
        font-weight: 500;
        font-size: 12rem;
        color: #9a9ca6;

        &:not(:first-child) {
          margin-left: 42rem;
        }
      }

      .dataActionIcon {
        height: 13rem;
        width: auto;
        margin-right: 7rem;
      }
    }
  }

  .transaction {
    margin-top: 22rem;

    .noRecent {
      margin-top: 10px;
      font-family: $font-third;
      font-size: 13rem;
      font-weight: 500;
      text-align: left;
      color: #9a9ca6;
    }

    .transactionHead {
      font-family: $font-third;
      font-size: 15rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #282828;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .clearButton {
        font-size: 12rem;
        color: #ffba49;
        background: none;
      }
    }

    .transactionList {
      list-style: none;
      padding: 0;
      margin: 15rem 0 0;
      max-height: 250px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #979aa3;
      }

      .transactionItem {
        font-family: $font-third;
        font-size: 13rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: left;
        color: #4a4e5e;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;

        &:not(:first-child) {
          margin-top: 12rem;
        }

        .checkIcon {
          width: 17rem;
        }

        .transactionName {
          display: flex;
          .transactionViewIcon {
            height: 8.5rem;
            margin: auto 0 auto 7rem;
          }
        }
      }

      @include breakpoint("sm-max") {
        max-height: 60px;
      }
      @include breakpoint("md-max") {
        max-height: 100px;
      }
    }
  }
}
