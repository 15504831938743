@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.back {
  font-size: 15px;
  font-weight: 500;
  color: $font-dark;

  //&:hover {}
}

.header {
  display: flex;
  align-items: center;

  margin-bottom: 20px;

  .titleModal {
    font-family: $font-third;
    font-size:20px;
    font-weight: bold;
    letter-spacing: 0.18px;
    text-align: center;
    color: #333;
    width: 94%;
  }

  .closeIcon {
    width: 6%;
    text-align: right;
    cursor: pointer;

    img {
      width: 15px;
    }
  }
}