$font-primary: Inter, sans-serif;
$font-secondary: 'Circular Std', sans-serif;
$font-third: 'Rajdhani';

$bg-light: #fafbfd;
$bg-white: #ffffff;
$bg-dark-gray: #9b98ac;
$bg-green: #37a742;
$bg-light-darker: #2e3448;

$bg-loading: #f0f0f0;
$bg-loading-layer: #e5e5e5;

$color-border: #ededed;

$color-dark: #2c2a38;
$color-gray-dark: #3a3a3a;
$color-light: #fff;
$color-medium: #9b98ac;
$color-gray-medium: #7c8085;
$color-green: #37b786;
$color-purple: #AC41D1;
$color-blue: #4451ee;
$color-red: #e94949;

$color-new-dark: #141617;
$color-new-light:  #7e8289;

$font-light: #ffffff;
$font-dark: #2c2a38;

// transition
$transition-all: all 0.15s ease-in;
$transition-color: color 0.15s ease-in;
$transition-opacity: opacity 0.15s ease-in;
