@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
  & :global {
    .ant-modal {
      width: 341.5rem !important;
      @include breakpoint("sm-max") {
        width: 300px !important;
      }
    }
    .ant-modal-body > div {
      padding: 23rem 30rem 19.5rem !important;
      width: 341.5rem;
      @include breakpoint("sm-max") {
        width: 300px;
      }
    }
  }

  .logo_container {
    width: 79rem;
    height: 50rem;
    // background-color: #cfcfcf;
    // border-radius: 50%;
    margin: 0 auto 22rem;

    .logo {
      width: 100%;
    }

    @include breakpoint("sm-max") {
      width: 60px;
      height: 60px;
    }
  }

  .heading {
    font-family: $font-third;
    font-size: 18rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.9rem;
    text-align: center;
    color: $color-gray-dark;
    margin: 0;

    @include breakpoint("sm-max") {
      font-size: 12px;
    }
  }

  .text {
    font-family: $font-third;
    font-size: 12rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.6rem;
    text-align: center;
    color: $color-gray-medium;
    margin: 15rem 0 0;

    @include breakpoint("sm-max") {
      font-size: 10px;
    }
  }

  .button {
    display: flex;
    width: 115rem;
    height: 32.5rem;
    border-radius: 6rem;
    margin: 19.5rem auto 0;
    font-family: $font-third;
    font-size: 12rem;
    font-weight: 600;
    line-height: 1.67;
    letter-spacing: 0.6px;

    @include breakpoint("sm-max") {
      margin-top: 30px;
      font-size: 11px;
      width: auto;
      height: auto;
    }
  }
}
