@import "/src/styles/variables/common";
@import "/src/styles/variables/common";

.stakeBtn {
    background-image: linear-gradient(to top, #3bae46, #56a15c);
    width           : 64rem;
    height          : 64rem;
    border-radius   : 15rem;

    border          : solid 3rem #fff;
    color           : #ffffff;
    font-size       : 12rem;
    box-shadow      : 1.7rem 4.2rem 15rem 0 rgba(0, 0, 0, 0.21);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
        margin-bottom: 7rem;
        margin-left: 1rem;

        @media screen and (orientation:landscape) and (max-height: 562px) {
            margin-bottom: 10rem;
        }
    }
    svg {
        width: 30rem;
        @media screen and (orientation:landscape) and (max-height: 562px) {
            height: 33rem;
        }
    }

    @media screen and (orientation:landscape) and (max-height: 562px) {
        width: 64rem;
        height: 64rem;
        font-size: 17rem;
    }
}

.tooltip {
    :global {
      .ant-tooltip-inner {
        font-family: $font-third;
        font-weight: 600;
        color: #fff;
        font-size: 10rem;
        background-color: #28539c;
        border-radius: 10px;
        box-shadow: 0 0 0 2rem #336dc7;

        min-height: auto;
      }

      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
            background-color: #336dc7;
            bottom: 0;
            width: 7rem;
            height: 7rem;
        }
      }
    }
}

.stakeBtnDisable {
    cursor: not-allowed;
    background-color: #4a5b75;

    width           : 64rem;
    height          : 64rem;
    border-radius   : 15rem;
    border          : solid 3rem #fff;
    color           : #ffffff;
    font-size       : 12rem;
    box-shadow      : 1.7rem 4.2rem 15rem 0 rgba(0, 0, 0, 0.21);
}

.stakeIcon {
    width: 30rem;
    margin-left: 5rem;
}