.content {
  max-width: 1280rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: 100rem;
  padding-right: 100rem;

  @include breakpoint("md-max") {
    padding-left: 23rem;
    padding-right: 23rem;
  }
}
