@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
	font-family: $font-third;
	.header {
		display: flex;
		flex-direction: column;
		align-items: center;

		.avatar {
			width: 80rem;
			height: 80rem;
			margin: 10rem;
			border-radius: 50%;
			background-color: #cfcfcf;
		}

		.loadingDiv {
			position: relative;
			width: 100%;
			height: 80rem;
			margin-bottom: 36rem;
			.loading {
				display: block;
				width: 80rem;
				height: 80rem;
				animation: rotate 1.5s linear infinite;
				position: absolute;
				left: 50%;
				top: 50%;
			}
			.loadingAvatar {
				width: 33rem;
				height: 33rem;
				border-radius: 50%;
				background-color: #cfcfcf;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.heading {
			font-size: 18rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: 0.9rem;
			text-align: center;
			color: $color-gray-dark;
			margin: 0;

			@include breakpoint("sm-max") {
				font-size: 21rem;
			}
		}

		.description {
			margin-top: 15rem;
			font-size: 11rem;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.64;
			letter-spacing: 0.55rem;
			text-align: center;
			color: #7c8085;
		}

		.loadingStaking{
			height: unset;

			@include breakpoint("sm-max") {
				height: 70px;
			}

			@include breakpoint("md-max") {
				height: 100px;
			}
		}
	}

	.buttonGroup {
		display: flex;
		justify-content: center;

		.button {
			height: 30rem;
			width: 80rem;
			border-radius: 5rem;
			margin: 5rem 8rem;
			font-size: 10rem;
			font-weight: 500;

			@include breakpoint("sm-max") {
				padding: 0 15rem;
			}
		}
	}

	.errMessage {
		color: red;
		font-size: 15px;
		@include breakpoint("sm-max") {
			font-size: 10px;
		}
	}

	.rowContainer {
		:global {
			.ant-radio{
				display: none;
			}
		}
	}

	.successContainer {
		display: flex;
		flex-direction: column;
		align-items: center;

		.imageCoin {
			background: url("../../assets/svg/balanceIcon.svg") no-repeat center / cover;
			width: 100px;
			height: 100px;
			@include breakpoint("sm-max") {
				width: 80px;
				height: 80px;
			}
			@include breakpoint("md-max") {
				width: 80px;
				height: 80px;
			}
		}

		.heading {
			font-size: 18rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: 0.9rem;
			text-align: center;
			color: $color-gray-dark;
			margin: 0 0 50px 0;

			@include breakpoint("sm-max") {
				font-size: 18rem;
				margin: 0 0 10px 0;
			}
			@include breakpoint("md-max") {
				font-size: 18rem;
				margin: 0 0 10px 0;
			}
		}

		.button{
			@include breakpoint("sm-max") {
				height: 25px;
				font-size: 18rem;
			}
			@include breakpoint("md-max") {
				height: 25px;
				font-size: 18rem;
			}
		}
	}

}

.stakeAmount {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 5rem 0;
	padding: 6rem;
	border-radius: 4rem;
	border: solid 1px #f0f3f6;

	.stake {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-family: $font-third;
		flex: 1;
		.title {
			font-size: 14rem;
			font-weight: 600;
			letter-spacing: 0.7px;
			color: #3a3a3a;
		}
		.amount {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.amountInput {
				border: none;
				padding: 0;
				border-radius: 0;
				:global {
					.ant-input {
						font-size: 18rem;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.25;
						letter-spacing: 0.9rem;
						text-align: left;
						color: #3a3a3a;
					}
				}
			}
		}
	}

	.balance {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.title {
			font-size: 14rem;
			font-weight: 600;
			letter-spacing: 0.7px;
			color: #3a3a3a;
		}
		.avatar {
			background: url("../../assets/svg/balanceIcon.svg") no-repeat center / cover;
			width: 18rem;
			height: 18rem;
			margin: 5rem;
			border-radius: 50%;
			background-color: #cfcfcf;
		}
		.amount {
			font-size: 18rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: 0.9rem;
			color: #3a3a3a;
			text-align: right;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			.amountInput {
				border: none;
				box-shadow: none;
			}
		}
		.maxBtn {
			margin-left: 15rem;
			padding: 4.5rem 8.5rem 5.5rem 9rem;
			border-radius: 4rem;
			background-color: #9feba7;
			font-size: 12rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: 0.6rem;
			text-align: center;
			color: #37a742;
			width: 40rem;
			height: 18rem;
		}
	}
}

@keyframes rotate {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
