@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
    text-align: center;

    .des {
        font-size: 15rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: 0.55rem;
        text-align: center;
        color: black;
    }
}