@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.copy {
  font-family: $font-primary;
  font-size: 13rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.08rem;
  text-align: left;
  color: #4f4f4f;

  img {
    margin-right: 5rem;
  }
}

.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;

}

.tooltip {
  :global {
    .ant-tooltip-inner {
      border-radius: 10rem;
      padding: 10rem;
      min-height: auto;
      line-height: 1;
      color: $color-light;
      background: $bg-dark-gray;
      font-family: $font-primary;
    }

    .ant-tooltip-arrow-content {
      background: $bg-dark-gray;
    }
  }
}

.icon {
  width: auto;
  height: auto;
  margin-right: 7rem !important;
}
