@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
	.placing {
		margin-top: 15rem;
		font-size: 15rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.64;
		letter-spacing: 0.55rem;
		text-align: center;
		color: black;
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: $font-third;

		.avatar {
			width: 80rem;
			height: 80rem;
			margin: 10rem;
			border-radius: 50%;
			background-color: #cfcfcf;

			.avatarSelected {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.heading {
			font-size: 18rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: 0.9rem;
			text-align: center;
			color: $color-gray-dark;

			margin: 0;

			@include breakpoint("sm-max") {
				font-size: 21rem;
			}
		}

		.description {
			margin-top: 15rem;
			font-size: 11rem;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.64;
			letter-spacing: 0.55rem;
			text-align: center;
			color: #7c8085;
		}

	}

	.buttonGroup {
		display: flex;
		justify-content: center;

		.button {
			height: 30rem;
			width: 80rem;
			border-radius: 5rem;
			margin: 5rem 8rem;
			font-size: 10rem;
			font-weight: 500;

			@include breakpoint("sm-max") {
				padding: 0 15rem;
			}
		}
	}
}