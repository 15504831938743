@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {

}

.header {
    .heading {
        display: grid;
        grid-template-columns: 95% 5%;
        align-items: center;

        .title {
            font-family: Rajdhani;
            font-size: 16rem;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 0.18px;
            text-align: center;
            color: #333;
        }

        .closeIcon {
            width: 15px;
            height: 15px;
            cursor: pointer;
            @include breakpoint("sm-max") {
                width: 6px;
                height: 6px;
            }
            @include breakpoint("md-max") {
                width: 8px;
                height: 8px;
            }
        }
    }

    .description {
        .des {
            font-family: Rajdhani;
            font-size: 13rem;
            font-weight: 600;
            font-style: normal;
            line-height: 1.64;
            letter-spacing: 0.55px;
            text-align: center;
            color: #7c8085;
            margin-top: 8px;
        }
    }
}

.content {
    margin-top: 20px;

    .verifyBtn {
        height: 30rem;
        width: 70rem;
        border-radius: 5rem;
        // margin: 5rem 8rem;
        font-size: 10rem;
        font-weight: 500;
        box-shadow: none;

        @include breakpoint("sm-max") {
            padding: 0 15rem;
        }
    }

    .listItem {
        margin-top: 10px;

        .item {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 8px;

            .radio {
                width: 14px;
                height: 14px;
                border: solid 1px #e7edee;
                border-radius: 50%;
                margin-right: 5px;
            }

            .radioChecked {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
    
            p {
                font-family: Rajdhani;
                font-size: 13rem;
                font-weight: 600;
                text-align: left;
                color: #282828;
            }
        }
    }

    .btnContainer {
        display: flex;
        justify-content: center;
        
        .button {
			height: 35rem;
			width: 110rem;
			border-radius: 5rem;
			margin: 5rem 8rem;
			font-size: 10rem;
            font-weight: 500;
            box-shadow: none;

			@include breakpoint("sm-max") {
				padding: 0 15rem;
			}
		}
    }

    :global {
        .ant-input-affix-wrapper{
            height: 34rem;
        }

        .ant-input{
            font-size: 13rem;
        }
    }
}