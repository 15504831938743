@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
}

.progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.main {
  max-width: 1280rem;
  margin: 0 auto;
  overflow: visible;
  background: #fafbfa;
}

.fade {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.5);

  visibility: hidden;
  opacity: 0;
  transition: $transition-all;

  &.fadeActive {
    visibility: visible;
    opacity: 1;
  }
}
