g {
  outline: none;
}

.unhoverImage {
  filter: brightness(0.7);
}

.snowImage {
  animation: snowHover .5s ease-in-out;
  animation-fill-mode: forwards;
  filter: url(#sofGlow);
}

.landImage {
  animation: landHover .5s ease-in-out;
  animation-fill-mode: forwards;
  filter: url(#sofGlow);
}

.dessertImage {
  animation: dessertHover .5s ease-in-out;
  animation-fill-mode: forwards;
  filter: url(#sofGlow);
}

.pinDoge {
  animation: dogeHover .5s ease-in-out;
  animation-fill-mode: forwards;
}

.pinPepe {
  animation: pepeHover .5s ease-in-out;
  animation-fill-mode: forwards;
}

.pinWojak {
  animation: wojakHover .5s ease-in-out;
  animation-fill-mode: forwards;
}

.pepeActive {
  animation: pepeSelect .5s ease-in-out;
}

.wojakActive {
  animation: wojakSelect .5s ease-in-out;
}

.dogeActive {
  animation: dogeSelect .5s ease-in-out;
}

.snowActive {
  animation: snowSelect .5s ease-in-out;
  animation-fill-mode: forwards;
}

.landActive {
  animation: landSelect .5s ease-in-out;
  animation-fill-mode: forwards;
}

.dessertActive {
  animation: dessertSelect .5s ease-in-out;
  animation-fill-mode: forwards;
}

.deactive {
  filter: brightness(0.4);
  -webkit-filter: brightness(40%);
  -moz-filter: grayscale(40%);
  -o-filter: brightness(40%);
  -ms-filter: brightness(40%);
  background: hsla(0,0%,0%,0.5);

  // -webkit-filter: invert(90%) sepia(51%) saturate(5207%) hue-rotate(136deg) brightness(92%) contrast(84%);
  // filter: invert(90%) sepia(51%) saturate(5207%) hue-rotate(136deg) brightness(92%) contrast(84%);   
  // -webkit-perspective: 1000;  
  // -webkit-backface-visibility: hidden; 
  
}

.background{
  transform: matrix(1.21, 0, 0, 1.21, -71, -41);
}

@keyframes snowHover {
  100% {
    transform: matrix(0.527, 0, 0, 0.527, -0.0536, 16.2609);
    opacity: 1;
  }
}

@keyframes landHover {
  100% {
    transform: matrix(0.5346, 0, 0, 0.5346, 208, -0.138);
    opacity: 1;
  }
}

@keyframes dessertHover {
  100% {
    transform: matrix(0.533, 0, 0, 0.533, 310, 3);
    opacity: 1;
  }
}

@keyframes landSelect {
  0% {
    transform: matrix(0.54, 0, 0, 0.54, 208, -0.838);
  }
  100% {
    transform: matrix(0.5146, 0, 0, 0.5146, 212.5888, -0.138)
  }
}

@keyframes snowSelect {
  0% {
    transform: matrix(0.53, 0, 0, 0.53, -0.06, 16);
  }
  100% {
    transform: matrix(0.507, 0, 0, 0.507, -0.0536, 16.2609);
  }
}

@keyframes dessertSelect {
  0% {
    transform: matrix(0.542, 0, 0, 0.542, 308, 1);
  }

  100% {
    transform: matrix(0.513, 0, 0, 0.513, 315.1992, 9.6697);
  }
}

@keyframes wojakSelect {
  0% {
    transform: matrix(0.25, 0, 0, 0.25, 90, 185);
  }
  100% {
    transform: matrix(0.16, 0, 0, 0.16, 95, 192);
  }
}

@keyframes pepeSelect {
  0% {
    transform: matrix(0.25, 0, 0, 0.25, 312, -12);
  }
  100% {
    transform: matrix(0.16, 0, 0, 0.16, 320, 0);
  }
}

@keyframes dogeSelect {
  0% {
    transform: matrix(0.25, 0, 0, 0.25, 542, 104);
  }
  100% {
    transform: matrix(0.16, 0, 0, 0.16, 550, 120);
  }
}

@keyframes pepeHover {
  100% {
    transform: matrix(0.21, 0, 0, 0.21, 314, -10);
  }
}

@keyframes wojakHover {
  100% {
    transform: matrix(0.21, 0, 0, 0.21, 93, 188);
  }
}

@keyframes dogeHover {
  100% {
    transform: matrix(0.21, 0, 0, 0.21, 546, 108);
  }
}