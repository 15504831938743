@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";


.footerContainer {
  max-width: 1280rem;
  margin: 0 auto;
  padding: 85rem 0 0;
  background: #fafbfa;

  @media screen and (min-width: 320px) and (max-width: 476px) {
    padding: 0;
    // min-height: 280px;
  }

  // on mobile landscape
  @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
    padding: 40rem;
    min-height: 170px;
  }

  // on Ipad portrait
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 40rem;
    min-height: 170px;
  }

  // on Ipad landscape
  @media screen and (orientation:landscape) and (min-width: 1024px) and (max-width: 1366px) {
    padding: 40rem;
    min-height: 170px;
  }

  .footerWrapper {
    max-width: 1080rem;
    margin: 0 auto;

    .divider {
      margin: 30rem 0;
      border-top-color: #d6dadd;
      padding-bottom: 30rem;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        display: none;
      }

      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        display: none;
      }
    }
  }

  .logoContainer {
    @media screen and (min-width: 320px) and (max-width: 476px) {
      display: flex;
      justify-content: center;
    }

    .logo {
      height: 62rem;
      object-fit: contain;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        height: 80rem;
        margin-bottom: 20rem;
      }
    }
  }

  .termContainer {
    display: flex;
    justify-content: center;

    a {
      font-size: 15rem;
      font-weight: 500;
      line-height: 2;
      color: #22282a;
      padding: 10rem 20rem;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        font-size: 19.5rem;
      }
    }

    &.desktop {
      @media screen and (min-width: 320px) and (max-width: 476px) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        display: flex;
        justify-content: space-between;
        padding: 45rem 87rem 40rem;
      }
    }
  }

  .social {
    width: 24.5rem;
    height: 24.5rem;
  }

  .socialButton {
    // margin-left: 10px;
    // grid-gap: 15rem;
    // gap: 15rem;

    a img {
      width: 24.5rem;
      object-fit: contain;
      margin-left: 10px;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        width: 39rem;
      }
    }
  }


  .socialButton, .socialButtonLabel {
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 320px) and (max-width: 476px) {
      justify-content: center;
    }
  }

  .socialButtonLabel {
    padding-right: 20rem;
    margin-bottom: 12.5rem;
    font-size: 18rem;
    font-weight: 500;

    @media screen and (min-width: 320px) and (max-width: 476px) {
      padding-right: 0;
      font-size: 23rem;
    }
  }
}
