@use "sass:math";
@import "/src/styles/mixins/breakpoints";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  // overflow: hidden;
  font-family: $font-primary;

  font-size: math.div(1, 1280) * 100vw;

  @media screen and (orientation:portrait) and (max-width: 562px) {
    font-size: math.div(1, 562) * 100vw;
  }

  @media screen and (orientation: landscape) and (max-height: 562px) {
    font-size: math.div(1, 562) * 100vh;
  }

  height: 100%;
}

body, h1, h2, h3, h4, h5, p, a {
  color: $font-dark;
  margin-bottom: 0;
  font-family: 'Rubik', 'SFProText-Light', Helvetica, Arial, sans-serif;
}

body {
  background: $bg-light;
  margin: 0;
  padding: 0;
  // overflow: hidden;
  // overflow-y: auto;
  // height: 100vh;
}

input[type=text], textarea, button {
  outline: none;
}

input[type=text]:focus, textarea:focus, button:focus, a:focus {
  outline: none;
}

// #root {
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   overflow: auto;
// }