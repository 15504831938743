.toggleSwitch {
    display: flex;
    justify-content: center;
    background-color: #f0f3f6;
    width: fit-content;
    border-radius: 5px;
    margin: 15rem auto 10px auto;

    .option {
        cursor: pointer;
        padding: 10rem 19rem 9rem 19rem;
        font-size: 12rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.6rem;
        text-align: center;
        margin: 2px;
    }

    .active {
        background-color: #ffffff;
        color: #37a742;
        border-radius: 5px;
        margin: 2px;
    }
}