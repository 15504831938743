@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";
@import "/src/styles/animations";

.container {
  height: 15rem;
  background-color: rgba(38, 77, 154, 0.31);
  display: flex;
  border-radius: 5rem;
  text-transform: uppercase;
  position: relative;
  color: white;
  overflow: hidden;

  .itemLeft {
    padding-left: 10rem;
    &:first-child {
      border-radius: 4rem;
    }
    &:not(:first-child) {
      border-radius: 0 4rem 4rem 0;
      margin-left: -4rem;
    }
    box-shadow:
            inset 0 0 2px 0 rgba(0,0,0,.4),
            inset 0 0 3px 0 rgba(0,0,0,.4),
            inset 0 0 3px 5px rgba(0,0,0,.05),
            2px 2px 4px 0 rgba(0,0,0,.25);
  }

  .loading {
    width: 100%;
    height: 100%;
    background-color: #1890ff;
    animation: $opacity-pulse;
    text-align: center;
  }

  .itemRight {
    padding-right: 12rem;

    &:last-child {
      border-radius: 4rem;
    }

    &:not(:last-child) {
      border-radius: 4rem 0 0 4rem;
      margin-right: -4rem;
    }
    box-shadow:
            inset 0 0 2px 0 rgba(0,0,0,.4),
            inset 0 0 3px 0 rgba(0,0,0,.4),
            inset 0 0 3px 5px rgba(0,0,0,.05),
            2px 2px 4px 0 rgba(0,0,0,.25);
  }

  .textLeft {
    font-family: $font-third;
    font-size: 10rem;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.25px;
    text-align: right;
    color: #fff;
    margin-bottom: 0;

    text-shadow: 0.1px 0.5px 1.5px rgba(0, 0, 0, 0.34);
    transform: translateY(0.5rem);
  }

  .textShadow {
    font-family: $font-third;
    font-size: 10rem;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.25px;
    text-align: right;
    color: #fff;
    margin-bottom: 0;

    text-shadow: 0.1px 0.5px 1.5px rgba(0, 0, 0, 0.34);
    transform: translateY(0.5rem);
    right: 12rem;

    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
      // transform: translateY(1rem);
      right: 16rem;
    }
  }
}