@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: transparent;
  padding: 18.5rem 0 16rem;
  box-sizing: border-box;
  z-index: 999;
  transition: $transition-all;

  @include breakpoint("sm-max") {
    padding: 20rem 0;
  }

  &.containerScrolled {
    background: #0059c3;
  }
}

.fullWidth {
  max-width: none;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.left {
  display: flex;
  align-items: center;
  width: 350rem;
  height: 50rem;
  padding-left: 35rem;
  background-position: right !important;

  background: url('./img/header_bg.png') center/cover no-repeat;
  @media screen and (orientation:landscape) and (max-height: 562px) {
    flex-shrink: 10;
    width: 327rem;
    height: 65rem;
  }
  @include breakpoint("sm-max") {
    width: 300rem;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 350rem;
  height: 50rem;
  background: url('./img/header_revert_bg.png') center/cover no-repeat;
  padding-right: 35rem;
  background-position: left;

  @media screen and (orientation:landscape) and (max-height: 562px) {
    height: 65rem;
    padding-right: 10rem;
  }

  @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
    padding-right: 5rem;
  }

  @include breakpoint("sm-max") {
    padding-right: 0rem;
  }

  .connectBtn {
    margin: 0 0 0 6rem;
    padding: 6.5rem 7.5rem 6rem 8.5rem;
    border-radius: 6.5rem;
    background-color: #3b7fda;
    box-shadow: inset 0 0 2.5rem 0 rgba(0, 0, 0, 0.3);

    font-size: 10rem;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  :global {
    .ant-dropdown-menu {
      box-shadow: inset -0.2rem 0.4rem 4rem 0 rgb(0 0 0 / 15%);
      background-color: #2b66b5;
    }

    .ant-dropdown-menu-item:hover{
      background-color: #3b7fda;
    }

    .ant-dropdown-menu-title-content{
      font-size: 10rem;
      font-family: "Rubik", sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
    }
  }

  .wallet {
    padding: 1rem 1.5rem 1rem 8.5rem;
    border-radius: 7.5rem;
    box-shadow: inset 0 0 2.5rem 0 rgba(0, 0, 0, 0.3);
    background-color: #2b66b5;

    font-size: 10rem;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;

    .walletBalance {
      padding: 6.5rem 7.5rem 6rem 8.5rem;
    }

    .walletAddress {
      margin: 0 0 0 6rem;
      padding: 6.5rem 7.5rem 6rem 8.5rem;
      border-radius: 6.5rem;
      background-color: #3b7fda;
      display: flex;
      align-items: center;
      
      .addressIcon {
        width: 12rem;
        height: 12rem;
        display: inline;
        margin-left: 6rem;
  
        @include breakpoint("md-max") {
          width: 15rem;
          height: 15rem;
          transform: translateY(-25%);
        }

        @include breakpoint("sm-max") {
          width: 11rem;
          height: 11rem;
        }
      }
    }



    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
      font-size: 12rem;
    }
    // @media screen and (orientation:landscape) and (max-height: 562px) {
    //   font-size: 15.5rem;
    // }


    .walletIcon {
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      margin-left: 6rem;

      @include breakpoint("sm-max") {
        width: 11rem;
        height: 11rem;
      }
    }

    @include breakpoint("sm-max") {
      font-size: 11rem;
    }
  }

  @include breakpoint("sm-max") {
    width: 300rem;
  }
}

.languagelist {
  margin-left: 12rem;
  margin-right: 7rem;
}

.headerBtn {
  width: 27rem;
  height: 32rem;
  border-radius: 7.5rem;
  box-shadow: inset -0.2rem 0.4rem 4rem 0 rgba(0, 0, 0, 0.15);
  background-color: #2b66b5;
  padding: 0 5rem;
  margin-left: 5px;
}

.moreIcon {
  object-fit: contain;
}

.logo {
  @include breakpoint("md-max") {
    max-width: 87rem;
    min-width: 40rem;
    width: 100%;
    flex-shrink: 10;
  }
}

.logoLink {
  padding: 0;

  .logo {
    height: 35rem;

    @include breakpoint("sm-max") {
      width: 85rem;
      height: 45rem;
      max-width: 85rem;
    }
  }
}

.nav {
}

.menuWrapper {
  width: 100%;
  margin-left: 27rem;

  @include breakpoint("md-max") {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    top: 100%;
    left: 0;
    background: $bg-light;
    margin: 0;

    transition: $transition-all;
    transform-origin: top;
    transform: scaleY(0) translateY(-100rem);
    opacity: 0;

    .open & {
      transform: scaleY(1) translateY(0);
      opacity: 1;
    }


  }

  @include breakpoint("sm-max") {
    height: calc(100vh - 42rem);
    overflow-y: auto;
  }
}

.searchWrapper {
  width: 100%;
  max-width: 422rem;
  flex-shrink: 10;
}

.search {
  width: 100%;

  @include breakpoint("md-max") {
    max-width: none;
  }
}

.menuBody {
  width: 100%;
  display: flex;
  align-items: center;

  @include breakpoint("md-max") {
    flex-direction: column;
    align-items: flex-start;
    padding: 24rem 23rem 28rem;
  }
}

.menuActions {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 422rem;
}

.menu {
  width: 100%;
  list-style: none;
  min-width: 0;
  margin: 0 0 0 45rem;
  padding: 0;

  display: flex;
  align-items: center;

  @include breakpoint("md-max") {
    margin: 30rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.menuItem {
  margin: 0;
  padding: 0;

  &:not(:first-child) {
    margin-left: 30rem;

    @include breakpoint("md-max") {
      margin: 15rem 0 0;
    }
  }
}

.dropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .itemMenu {
    @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
      font-size: 9rem;
    }
  }
}

.menuLink {
  font-family: $font-primary;
  font-size: 13rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #2e3345;
  padding: 5rem 0;

  transition: $transition-all;

  &:link {
    text-decoration: none;
  }

  &:visited {
  }

  &:hover {
    opacity: 0.5;
    color: #2e3345;
  }

  &:active {
  }
}

.menuLinkActive {
}

.button {
  flex-shrink: 0;
}

.buttonNotify {
  margin-left: 10rem;
}

.buttonNotifyIcon {
  width: 31rem;
  height: auto;
}

.hamburger {
  margin-left: 23rem;
  flex-shrink: 0;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchDropdownMobileWrapper {
  width: 100%;
}

.searchDropdownMobile {
  width: 100%;
  background: transparent;
  padding-top: 20rem;
}

.roundHistoryModal {
  @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 476px) {
    // display: none;
  }
}
