@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {
  border-radius: 7.5px;
  border: 0.5px solid #e7edee;
  padding: 0 10px;
  height: 32rem;
  display: flex;
  align-items: center;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 50px $color-light;
    color: $color-medium !important;
    -webkit-text-fill-color: $color-medium !important;
  }

  @include breakpoint("sm-max") {
    height: 40px;
  }

  :global {
    .ant-input {
      font-family: Rajdhani;
      font-size: 12rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #333;

      @include breakpoint("sm-max") {
        font-size: 13px;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: inset 0 0 0 50px $color-light;
        color: $color-medium !important;
        -webkit-text-fill-color: $color-medium !important;
      }

      &::placeholder {
        font-family: Rajdhani;
        font-size: 12rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #bdbaba;

        @include breakpoint("sm-max") {
          font-size: 12rem;
        }
      }
    }
  }

  &:global.ant-input-affix-wrapper-disabled {
    background-color: #f5f7f9;
  }
}

.containerSmall {
  height: 37px;

  :global {
    .ant-input {
      font-size: 13px;
    }
  }
}

.containerIcon {
  padding: 0 15px 0 21.5px;

  :global {
    .ant-input {
      margin-left: 14.5px;
    }
  }
}

.containerLight {
  background-color: #fff;
  :global {
    .ant-input {
      background-color: #fff;
    }
  }
}

.prefix {
  max-width: 16.5px;
  max-height: 16.5px;
}
