@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.itemList {
	display: flex;
	flex-direction: column;

	.raidoItem {
		display: grid;
		grid-template-columns: 85% 15%;
		align-items: center;
		margin: 5rem 0;
		padding: 6rem;
		border-radius: 4rem;
		border: solid 1px #f0f3f6;

		:global {
			.ant-radio-input {
				background-color: #3bae46;
			}

			.ant-radio-wrapper:after {
				display: inline-block;
				width: 0;
				overflow: hidden;
				content: "";
			}
		}

		.radio {
			display: grid;
			grid-template-columns: 5% 95%;
			align-items: center;
			padding: 2rem;
			font-family: $font-third;

			.info {
				display: grid;
				grid-template-columns: 60% 40%;
				align-items: center;

				.title {
					font-size: 12rem;
					font-weight: 600;
					letter-spacing: 0.6rem;
					color: #3a3a3a;
				}
	
				.description {
					font-size: 11rem;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.36;
					letter-spacing: 0.55rem;
					text-align: left;
					color: #7c8085;
				}

				.text {
					display: flex;
					justify-content: flex-end;

					p {
						font-size: 12rem;
						font-weight: 600;
						letter-spacing: 0.6rem;
					}
				}
			}


			& :global {
				.ant-radio-checked {
					.ant-radio-inner {
						border-color: #3bae46;
						&:after {
							background-color: #3bae46;
						}
					}
				}
			}
		}

		.radioImage {
			height: 30rem;

			img {
				height: 100%;
				border-radius: 5rem;
			}
		}
	}

	@include breakpoint("sm-max") {
		:global {
			.ant-radio {
				.ant-radio-inner {
					width: 10px;
					height: 10px;
					&::after {
						top: 1px;
						left: 1px;
					}
				}
			}
		}
	}
	@include breakpoint("md-max") {
		:global {
			.ant-radio {
				.ant-radio-inner {
					width: 10px;
					height: 10px;
					&::after {
						top: 1px;
						left: 1px;
					}
				}
			}
		}
	}
}