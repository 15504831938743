.container {
  height: 15rem;
  background-color: rgba(38, 77, 154, 0.31);
  display: flex;
  border-radius: 5rem;
  text-transform: uppercase;

  .itemLeft {
    padding-left: 10rem;
    overflow: hidden;
    &:first-child {
      border-radius: 4rem;
    }
    &:not(:first-child) {
      border-radius: 4rem;
      // border-radius: 0 4rem 4rem 0;
      // margin-left: -4rem;
    }
    box-shadow: inset 0 0 1px #333;
  }
  .itemRight {
    padding-right: 10rem;
    &:last-child {
      border-radius: 4rem;
    }
    &:not(:last-child) {
      border-radius: 4rem 0 0 4rem;
      margin-right: -4rem;
    }
    box-shadow: inset 0 0 4px #333;
  }
}