@import "/src/styles/variables/common";

.container {
  padding: 7rem 8rem;
  margin-top: 12rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7.5rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-width: 0;
  box-shadow: 0.2rem 1.5rem 7.5rem 0 rgba(0, 0, 0, 0.3);

  display: flex;

  .nationVoteImage {
    width: 75rem;
    margin-right: 15rem;

    @media screen and (orientation:landscape) and (max-height: 562px) {
      width: 98rem;
      margin-right: 19rem;
    }
  }

  .nationVoteContent {

  }

  .nationVoteTitle {
    font-family: $font-third;
    font-size: 14rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.7rem;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;

    @media screen and (orientation:landscape) and (max-height: 562px) {
      font-size: 18rem;
    }
  }

  .nationVoteVersus {
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;
    width: 260rem;


    @media screen and (orientation:landscape) and (max-height: 562px) {
      width: 320rem;
    }
  }

  .nationVoteItem {
    border-radius: 4rem;
    padding: 3.5rem;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.5px 0 rgba(0, 0, 0, 0.31);
    background-color: rgba(38, 77, 154, 0.31);

    .nationVoteItemIcon {
      width: 25rem;
      height: 25rem;
      margin-right: 6rem;
      border-radius: 4rem;
      background: white;
      object-fit: contain;

      @media screen and (orientation:landscape) and (max-height: 562px) {
        width: 30rem;
        height: 30rem;
      }
    }

    .nationVoteItemContent {
      font-family: $font-third;
      font-size: 10rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 0.5px;
      text-align: left;
      color: #ffffff;

      @media screen and (orientation:landscape) and (max-height: 562px) {
        font-size: 14rem;
      }
      .itemTitle {
        font-weight: bold;
      }
      .itemPercent {

      }

    }
  }
}