@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";


.sectionThree {
  padding: 80rem 40rem;

  // @include breakpoint("sm-max") {
  //   margin-top: 0;
  //   padding: 0 89rem;
  // }
  @include breakpoint("sm-max") {
    padding: 40rem 40rem;
  }

  .AstroImage{
    height: 430rem;
  }

  .heading {
    font-family: $font-third;
    font-size: 35rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    color: #28292a;
    @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
      font-size: 45rem !important;
    }
    @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
      font-size: 45rem !important;
    }
  }

  .titleHeading {
    padding-top: 5rem;
    font-size: 15rem;
    @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
      font-size: 19.5rem !important;
    }
    @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
      font-size: 19.5rem !important;
    }
  }

  ul {
    list-style: none; /* Remove default bullets */
  }

  // li::before {
  //   content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  //   color: transparent; /* Change the color */
  //   font-weight: bold; /* If you want it to be bold */
  //   display: inline-block; /* Needed to add space between the bullet and the text */
  //   width: 1em; /* Also needed for space (tweak if needed) */
  //   margin-left: -1em; /* Also needed for space (tweak if needed) */
  // }

  .list {
    list-style: none;
    padding: 0;
    margin-top: 60rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;

    // on mobile portrait
    @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
      row-gap: 31rem;
    }

    // on mobile landscape
    @media screen and (orientation:landscape) and (min-width: 768px) and (max-width: 936px) {
    }

    // on iPad normal portrait
    @media screen and (orientation:portrait) and (min-width: 768px) and (max-width: 1023px) {
    }

    // special for ipad pro 11
    @media screen and (orientation:portrait) and (min-width: 834px) and (max-width: 1194px) {
      grid-template-columns: 1fr 1fr;
    }

    // on iPad landscape
    @media screen and (orientation:landscape) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    // on iPad pro portrait
    @media screen and (orientation:portrait) and (min-width: 1024px) and (max-width: 1365px) {
      grid-template-columns: 1fr 1fr;
    }

    // on iPad all landscape
    @media screen and (orientation:landscape) and (min-width: 1024px) and (max-width: 1366px) {
    }

    .item {
      border-radius: 10rem;
      box-shadow: 3.7rem 9.3rem 50rem 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 16rem 20rem;
      text-align: center;

      @include breakpoint("sm-max") {
        width: 100%;
        height: fit-content;
        padding: 30rem;
      }

      .itemImage {
        // width: 92rem;
        height: 95rem;


        @include breakpoint("sm-max") {
          // width: 124rem;
          height: 124rem;
        }
      }

      .itemHeading {
        font-family: $font-third;
        font-size: 17.5rem;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #282828;
        margin: 25rem 0 0;

        @include breakpoint("sm-max") {
          font-size: 25rem;
        }
      }

      .itemText {
        font-family: $font-primary;
        font-size: 12.5rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #282828;
        margin: 10rem 0 0;

        @include breakpoint("sm-max") {
          font-size: 18rem;
        }
      }
    }
  }

  .btnlogo{
    display: flex;
    align-items: center;
    .badgeIcon{
      height: 60rem !important;
      width: auto;
    }
    .textHeading{
      font-size: 45rem;
      margin-left: 6rem;
    }
    @media only screen and (min-width: 563px) and (max-width: 768px){
      justify-content: flex-start !important;
    }
  }

  .memepadContent {
    padding-bottom: 80rem;
    padding-left: 50rem;
    padding-right: 50rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;

    @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    .titleWrapper {
      // display: flex;
      // align-items: center;

      .infoContent{
        font-family: Inter;
        font-size: 15rem;
        line-height: 1.33;
        text-align: left;
        color: #FFFFFF;
        margin-top: 20rem;
        @include breakpoint("sm-max") {
          font-size: 18rem;
        }
        @media only screen and (min-width: 563px) and (max-width: 768px){
          text-align: left !important;
        }
        @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
          font-size: 19.5rem !important;
        }
        @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
          font-size: 19.5rem !important;
        }
      }

      .subHeading{
        margin-top: 3.5rem;
        @media only screen and (min-width: 563px) and (max-width: 768px){
          text-align: left !important;
        }
      }

      .primaryBtn{
        padding : 18px 30px;
        font-family: $font-primary !important;
        font-weight: 800;
        border-radius: 6px;
        background-color:#0089fe;
        margin-top: 25rem;
        font-size: 15rem;
        border-color: transparent;
        cursor: pointer;

        @include breakpoint("sm-max") {
          padding : 10px 15px;
        }
        
        &:hover {
          background-color: #0046ac;
        }
        @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
          font-size: 18rem !important;
        }
        @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
          font-size: 18rem !important;
        }
      }

      .title {
        max-width: 245rem;

        @include breakpoint("sm-max") {
          max-width: 100%;
        }
      }

      .tokenIcon {
        width: 54rem;
        height: 54rem;
        margin-left: 17.5rem;
      }

      .atackIcon {
        width: 62rem;
        height: 54rem;
        margin-left: 12rem;
      }

      .artboardIcon {
        width: 83rem;
        height: 52rem;
        margin-left: 17.5rem;
      }
    }
  }


  // chart content
  .chartContent {
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    .textContentRight{
      .customBadge {
        span{
          height: 15rem !important;
          width: 15rem !important;
          margin-right: 5rem;
        }
      }
      align-self: flex-end;
    }
    .imageContent {
      display: flex;
      justify-content: flex-end;
      img{
        height: 235rem;
        width: auto;
      }
    }
    .statsTitle{
      font-size: 17rem;
      margin-left: 30rem;
      font-weight: 500;
      p {
        margin: 10rem 0rem;
      }
      .MainHeading{
        font-size: 20rem;
        font-weight: 600 !important;
        @media only screen and (max-width: 500px) {
          margin-left: 112rem;
          width: 100%;
        }
        @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
          font-size: 22rem !important;
        }
        @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
          font-size: 22rem !important;
        }
      }
      @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
        font-size: 19.5rem !important;
      }
      @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
        font-size: 19.5rem !important;
      }
    }
    .statsContent{
      font-size: 16rem;
      font-weight: 500;
      p {
        margin: 10rem 0rem;
      }
      @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
        font-size: 19.5rem !important;
      }
      @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
        font-size: 19.5rem !important;
      }
    }

    @include breakpoint("sm-max") {
      .statsTitle{
        margin-left: 0rem;
      }
    }
  }
}

// war content
.warsContent {
  margin-top: 15rem;
}

@include breakpoint("sm-max") {

  .memepadContent{
    // padding-top: 50rem;
    padding-bottom: 80rem !important;
  }
  .btnlogo{
    justify-content: center;
  }
  .infoContent{
    justify-content: center;
    .Heading{
      text-align: center;
    }
    .subHeading{
      text-align: center;
    }
    .btnCenter{
      text-align: center;
      @media only screen and (min-width: 563px) and (max-width: 768px){
        text-align: left !important;
      }
    }
  }
  .chartContent {
    .imageContent {
      display: flex;
      justify-content: center !important;
      img{
        height: 235rem;
        width: auto;
      }
    }
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
  }
  // .textContentRight {
  //   display: flex;
  //   justify-content: center;
  // }
  .textContentLeft, .textContentRight {
    display: flex;
    justify-content: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .textHeading {
    font-size: 40rem !important;
  }
  .textContentRight {
    @media only screen and (max-width: 500px) {
      margin-top: 50rem !important;
      width: 100%;
    }
  }
}


