@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.hide {
  display: none !important;
}
.nationInfoContainer {
  padding: 8.5rem 10rem 5.5rem 9rem;
  // width: 530rem;
  border-radius: 8rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: solid 1px #68b0f2;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom-width: 0;
  position: absolute;
  bottom: 0;
  z-index: 999;


  // @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
  //   background-color: #68b0f2;
  // }

  @media screen and (orientation:landscape) and (min-width: 844px) and (max-width: 1366px)  {
    bottom: 15rem;
    border-bottom-width: 1px;
    border-bottom-left-radius: 8rem;
    border-bottom-right-radius: 8rem;
  }

  display: flex;
  align-items: center;

  .nationImage {
    width: 84rem;
    margin-right: 15rem;
    border-radius: 10px;
  }

  .nationInfo {
    color: #ffffff;
    display: flex;
    flex-direction: column;

    .nationName {
      display: flex;
      align-items: center;

      font-family: $font-third;
      font-size: 14rem;
      font-weight: bold;
      line-height: 1.29;
      letter-spacing: 0.7px;
      text-align: left;
      color: #fff;
      text-transform: uppercase;

      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        font-size: 10.5px;
      }
    }

    .info {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .infoItemBox {
        // width: 50%;
        padding: 2rem;

        .infoItem {
          display: flex;
          flex-direction: row;
          align-items: center;

          font-family: $font-third;
          font-size: 11.56rem;
          font-weight: 500;
          line-height: 1.67;
          letter-spacing: 0.45px;
          text-align: left;
          color: #fff;

          // width: 180rem;
          border-radius: 4rem;
          box-shadow: inset 0 0 1.5px 0 rgba(0, 0, 0, 0.31);
          background-color: rgba(0, 0, 0, 0.075);

          // box-shadow: inset 0 0 1.5rem 0 rgba(0, 0, 0, 0.31);
          // background-color: #224d9a;


          @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            // font-size: 10.5px;
          }

          .infoImage {
            width: 12rem;
            margin: 3rem;
            fill: white;
            font-weight: bold;
          }

          .valueSpan {
            font-family: $font-third;
            font-size: 11.56rem;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: 0.45px;
            text-align: left;
            color: #fff;

            @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
              // font-size: 10.5px;
            }
          }

          span {
            font-size: 11.56rem;
            text-transform: uppercase;
          }
        }
      }
    }

    .infoProgress {
      margin-top: 3rem;
      font-size: 9rem;
    }
  }
}

.showBtn {
  width: 84rem;
  height: 20rem;
  z-index: 999;

  background-color: rgba(255, 255, 255, 0.1);
  border: solid 1px #68b0f2;
  border-bottom-width: 0;
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  svg {
    width: 16rem;
    height: 10rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}


.hideBtn {
  width: 84rem;
  height: 20rem;
  z-index: 999;

  // background-color: rgba(255, 255, 255, 0.1);
  background-color: #68b0f2;
  border: solid 1px #68b0f2;
  border-bottom-width: 0;
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
  position: absolute;
  left: 50%;
  bottom: 138rem;
  transform: translateX(-50%);

  @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
    bottom: 98rem;
  }

  // @media screen and (orientation:landscape) and (min-width: 844px) and (max-width: 1366px)  {
  //   bottom: 115rem;
  // }

  svg {
    width: 16rem;
    height: 10rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}