@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
  .head {
    background: #edeff4;
    min-height: 45rem;
    padding: 17rem 60rem 17rem 23rem;
  }

  .title {
    font-family: $font-primary;
    font-size: 15rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $font-dark;
    margin: 0;
  }

  .body {
    padding: 26rem 23rem 40rem;

    &.noTitle {
      padding: 20rem 23rem 20rem;
    }
  }

  :global {
    .ant-modal-content {
      border-radius: 15rem;
      background: #fff;
      padding: 0;
      overflow: hidden;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-close-x {
      margin-right: 7rem;
      margin-top: 5rem;
    }
  }

}
