@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.roundSection {
  width: 376rem;
  
  .round {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 1.5rem;

    .iconWrapper {
      background-color: #336bc1;
      width: 72rem;
      height: 72rem;
      border-radius: 50%;
      box-shadow: 0.2rem 1.5rem 7.5rem 0 rgb(0 0 0 / 30%);
    }
    .icon {
      position: absolute;
      top: 8rem;
      left: 8rem;
      width: 59rem;
      border-radius: 50%;
      box-shadow: 0 0 0 3rem #ffffff;
      z-index: 110;
    }
    .roundBox {
      width: auto;
      // width: 185rem;
      // height: 45rem;
      padding: 6rem 10rem 9.5rem 10rem;
      background-color: #336bc1;
      // background-image: radial-gradient(circle at 50% 50%, #008aff, #0040a4);
      margin-left: -14rem;
      border-radius: 6rem; 
      box-shadow: 4rem 4rem 5rem 0 rgb(0 0 0 / 20%);
      display: flex;
      justify-content: flex-end;
      padding-left: 28rem;
      color: #ffffff;

      .roundTitleProgress {
        width: 100%;

        .roundTitle {
          display: flex;
          font-family: $font-third;
          font-size: 12rem;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 0.6px;
          color: #ebebeb;
          text-transform: uppercase;
          margin-bottom: 0px;

          @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            font-size: 7px;
          }

          // @include breakpoint("sm-max") {
          //   font-size: 8rem !important;
          // }

          // @include breakpoint("md-max") {
          //   font-size: 8rem;
          // }

          .iconRoundTitle {
            margin-left: 5px;
            cursor: pointer;
            position: relative;
            z-index: 2;
          }
        }
        .roundProgress {
          box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.58);
          background-color: #28539c;

          @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            font-size: 6.5px;
          }
        }
      }
    }
  }
}
