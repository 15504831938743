@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0s 0.65s;

  &.containerVisible {
    transition: visibility 0s;
    visibility: visible;
  }
}

.bgMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $bg-light;
  z-index: 0;
  animation: hide-mask 0.3s 0.3s ease-out forwards;

  .containerVisible & {
    animation: none;
  }
}

@keyframes hide-mask {
  to {
    opacity: 0;
  }
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: hide 0.3s 0.25s ease-out forwards;

  @include breakpoint("sm-max") {
    animation-name: hide-mobile;
  }

  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .containerVisible & {
    animation: none;
  }

  .logoContainer {
    position: relative;
    height: 100vh;
    width: 266.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint("sm-max") {
      width: 100%;
      height: 100vh !important;
      display: flex;
      justify-content: center !important;
    }
  }

  .gamesharkWrapper {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    animation: disappear 0.25s ease-out forwards;

    @include breakpoint("sm-max") {
      bottom: 9%;
      text-align: center;
    }

    .containerVisible & {
      opacity: 0;
      animation: appearAndMove 0.25s 0.55s ease-in forwards;
    }
  }

  .poweredBy {
    font-family: $font-secondary;
    font-size: 20rem;
    font-weight: bold;
    text-align: center;
    color: $color-light;
    line-height: 1;
    margin-bottom: 12rem;

    @include breakpoint("sm-max") {
      font-size: inherit;
    }
  }

  .poweredByIcon {
    width: 266.5rem;
    height: 74rem;

    @include breakpoint("sm-max") {
      width: 216.5rem;
      height: unset;
    }

    @include breakpoint("md-max") {
      width: 216.5rem;
      height: unset;
    }
  }
}

@keyframes hide {
  to {
    transform: translateX(100%);
  }
}

@keyframes hide-mobile {
  to {
    transform: translateY(-100%);
  }
}

.loader {
  position: relative;
  z-index: 2;
  animation: disappear 0.25s ease-out forwards;
  width: 240rem;
  margin: auto;
  // top: 26%;

  @media screen and (orientation:landscape) and (min-width: 563px) and (max-width: 768px) {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 350rem !important;
    margin: auto;
    // top: 26% !important;
  }
  @media screen and (orientation:portrait) and (min-width: 563px) and (max-width: 768px) {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 350rem !important;
    margin: auto;
    // top: 26% !important;
  }
  @media screen and (orientation:portrait) and (max-width: 992px) {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 350rem !important;
    margin: auto;
    // top: 35% !important;
  }
  @media screen and (orientation:landscape) and (max-width: 992px) {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 350rem !important;
    margin: auto;
    // top: 35% !important;
  }
  @media screen and (orientation:portrait) and (max-width: 1024px) {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 350rem !important;
    margin: auto;
    // top: 40% !important;
  }
  @media screen and (orientation:landscape) and (max-width: 1024px) {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 350rem !important;
    margin: auto;
    // top: 40% !important;
  }

  .containerVisible & {
    transform: scale(0.75);
    opacity: 0;
    animation: appear 0.25s 0.35s ease-in forwards;
  }

  @include breakpoint("sm-max") {
    justify-content: center !important;
    width: 200rem;
    top: 0% !important; 
  }
}

@keyframes appear {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes appearAndMove {
  to {
    transform: scale(1) translateX(-50%);
    opacity: 1;
  }
}

@keyframes disappear {
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
