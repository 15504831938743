@import "/src/styles/variables/common";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  background: linear-gradient(90deg, #002b93, #0070e8, #002b93);

  @media screen and (orientation:portrait) {
    display: none;
  }
}

.boats {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 800rem;
  z-index: 1;


  @media screen and (orientation:landscape) and (max-height: 562px) {
    transform: translate(-50%, calc(-50% + 50rem));
  }
}

.mapRegions {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% + 25rem));
  width: 562rem;
  z-index: 999;

  @media screen and (orientation:landscape) and (max-height: 562px) {
    transform: translate(-50%, calc(-50% + 75rem));
  }
}

.maps {
  width: 100vw;
  height: 100vh;
}

.pointContainer {
  display: flex;  
  justify-content: space-between;
  width: 100%;
  padding: 0 35rem;
  position: absolute;
  top: 100rem;
  @media screen and (orientation:landscape) and (max-height: 562px) {
    padding: 0 10rem;
  }
}

.bottomButtonsContainer {
  position: absolute;
  bottom: 0;
  display: flex;  
  justify-content: space-between;
  width: 100%;
  padding: 35rem;

  .voteBtn{
    background-image: linear-gradient(to top, #3b71db, #4677d4);
    flex-direction: column;
    width: 70rem;
    height: 70rem;
    border-radius: 15rem;
    border: 4rem solid #ffffff;
    color: #ffffff;
    font-size: 12rem;

    svg {
      width: 30rem;
    }
  }

  .stakeBtn{
    background-image: linear-gradient(to top, #3bae46, #56a15c);
    flex-direction: column;
    width: 70rem;
    height: 70rem;
    border-radius: 15rem;
    border: 4rem solid #ffffff;
    color: #ffffff;
    font-size: 12rem;
    svg {
      width: 30rem;
    }
  }
}

.attackContainer {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50%));
  width: 800rem;
  z-index: 999;

  @media screen and (orientation:landscape) and (max-height: 562px) {
    transform: translate(-50%, calc(-50% + 50rem));
  }
}