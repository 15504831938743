@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.newsContainer {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0 0 7.5rem 7.5rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-width: 0;
  box-shadow: 0.2rem 1.5rem 7.5rem 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: $font-third;
  font-size: 11rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  width: 370rem;
  margin: 0 auto;

  .newsIcon {
    width: 15rem;
    margin-right: 5rem;

    @media screen and (orientation:landscape) and (max-height: 562px) {
      width: 16rem;
    }
  }
  .newsTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #5ca1e550;
    font-size: 10rem;
    font-weight: bold;
    border-radius: 0 0 0 7.5rem;
    padding: 3rem 6rem;
    text-transform: uppercase;

    @media screen and (orientation:landscape) and (max-height: 562px) {
      font-size: 14rem;
    }
  }

  .newsContent {
    width: 100%;
    overflow: hidden;
  }
  .newsDescription {
    color: #ffffff;
    font-weight: 400;
    width: 100%;
    animation: left_to_right 16s ease;
    animation-iteration-count: infinite;
    white-space: nowrap;

  }

  @media screen and (orientation:landscape) and (max-height: 562px) {
    font-size: 14rem;
  }
}


@keyframes left_to_right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}