@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";


.sectionFive {
  margin: 85rem auto 0;
  background-image: url("assets/section5-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1080rem;
  height: 300rem;
  border-radius: 10rem;

  @media screen and (min-width: 320px) and (max-width: 476px)  {
    background: url("assets/section5-bg-mobile.png") center/cover no-repeat;
    height: 810rem;
    margin: 90rem 35rem;
  }

  @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
    max-width: 1080rem;
    height: 300rem;
  }

  // on Ipad not pro
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 100%;
    margin: 90rem 35rem;
  }

  .wrapper {
    height: 300rem;

    .information {
      padding: 0 0 0 70rem;

      @media screen and (min-width: 320px) and (max-width: 476px) {
        padding: 0;
        text-align: center;
      }

      @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
        text-align: left;
      }

      .content {
        .heading {
          font-size: 35rem;
          font-family: $font-third;
          font-weight: bold;
          line-height: 1;
          color: #ffffff;

          @media screen and (min-width: 320px) and (max-width: 476px) {
            font-size: 32.5rem;
          }
        }

        .buttonContainer {
          display: flex;
          margin-top: 22rem;

          @media screen and (min-width: 320px) and (max-width: 476px) {
            justify-content: center;
            margin-top: 28.5rem;
          }

          @media screen and (orientation:landscape) and (min-width: 480px) and (max-width: 926px) {
            justify-content: flex-start;
          }

          .btnWrapper {
            display: flex;
            align-items: center;
          }

          .label {
            font-size: 15rem;
            font-weight: 500;
            line-height: 2;
            color: #ffffff;
            padding: 0;

            @media screen and (min-width: 320px) and (max-width: 476px) {
              font-size: 23rem;
              margin-left: 7rem;
            }
          }

          .btn {
            border-radius: 5rem;
            width: auto;
            height: 38rem;
            display: flex;
            align-items: center;
            margin-right: 10rem;
            text-align: center;
            padding: 0 18.5rem;

            @media screen and (min-width: 320px) and (max-width: 476px) {
              height: 56.5rem;
            }
          }

          .btnJoin {
            background: url("/assets/img/home/btn-orange-bg.svg") center/cover no-repeat;

            @media screen and (min-width: 320px) and (max-width: 476px) {
              margin-right: 15.5rem;
            }

            .telegramIcon {
              width: 14.5rem;
              height: 12.5rem;
              margin-right: 5.5rem;

              @media screen and (min-width: 320px) and (max-width: 476px) {
                width: 22rem;
                height: 19rem;
              }
            }
          }

          .btnContact {
            background: url("assets/coming-soon-btn-bg.svg") center/cover no-repeat;

            .pancakeIcon {
              width: 18rem;
              margin-right: 5.5rem;

              @media screen and (min-width: 320px) and (max-width: 476px) {
                width: 27rem;
                height: 27rem;
              }
            }
          }
        }
      }
    }
  }
}
